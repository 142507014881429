var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Usuarios "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.newUser()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nuevo ")],1)]}}])},[_c('span',[_vm._v("Nuevo usuario")])])],1),_c('v-breadcrumbs',{attrs:{"items":_vm.routes},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"to":item.href,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.users_search),callback:function ($$v) {_vm.users_search=$$v},expression:"users_search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.users_headers,"items":_vm.users_data,"search":_vm.users_search,"loading":_vm.users_loading,"loading-text":_vm.users_loading_text,"no-data-text":"Sin datos","no-results-text":"Sin resultados"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.role == 'admin')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"warning","rounded":""},on:{"click":function($event){return _vm.viewUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_vm._v(" | "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.editUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"warning","rounded":""},on:{"click":function($event){return _vm.viewUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_vm._v(" | "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.editUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_vm._v(" | "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","small":"","color":"error","rounded":""},on:{"click":function($event){return _vm.deleteUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }