<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Usuarios

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              color="primary"
              @click="newUser()"
            >
              <v-icon>mdi-plus</v-icon>
              Nuevo
            </v-btn>
          </template>
          <span>Nuevo usuario</span>
        </v-tooltip>
      </v-card-title>

      <v-breadcrumbs :items="routes">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>

    <v-text-field
      v-model="users_search"
      label="Buscar"
      append-icon="mdi-magnify"
      clearable
    ></v-text-field>

    <v-data-table
      :headers="users_headers"
      :items="users_data"
      :search="users_search"
      :loading="users_loading"
      :loading-text="users_loading_text"
      no-data-text="Sin datos"
      no-results-text="Sin resultados"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <div v-if="item.role == 'admin'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                color="warning"
                rounded
                @click="viewUser(item)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
          |
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                color="primary"
                rounded
                @click="editUser(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </div>

        <div v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                color="warning"
                rounded
                @click="viewUser(item)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
          |
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                color="primary"
                rounded
                @click="editUser(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          |
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                small
                color="error"
                rounded
                @click="deleteUser(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "RegisterIndex",
  components: {},
  data: () => ({
    // Navigation
    routes: [
      {
        text: "Usuarios",
        disabled: true,
        href: "/user/index",
      },
    ],
    // Form Data
    users_search: null,
    users_loading: true,
    users_loading_text: "Obteniendo datos",
    users_headers: [
      {
        text: "Nombre de usuario",
        align: "start",
        sortable: false,
        value: "username",
      },
      { text: "Rol", value: "role" },
      { text: "Acciones", value: "actions" },
    ],
    users_data: [],
  }),
  mounted() {
    this.valityUser();
    this.getUsers();
  },
  methods: {
    valityUser(){
      let role = this.$store.getters.getUser.role;

      if (role != 'admin') this.$router.push("/register/index");
    },
    getUsers() {
      this.users_loading = true;
      this.users_loading_text = "Obteniendo datos";

      this.$axios
        .get("https://fiel.compratec.com.mx/backend/public/api/user")
        .then((response) => {
          this.users_data = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.users_loading = false));
    },
    viewUser(item) {
      this.$router.push("/user/view/" + item.id);
    },
    newUser() {
      this.$router.push("/user/new");
    },
    editUser(item) {
      this.$router.push("/user/edit/" + item.id);
    },
    deleteUser(item) {
      this.registers_data = [];
      this.registers_loading = true;
      this.registers_loading_text = "Eliminando registro";

      this.$axios
        .delete(
          "https://fiel.compratec.com.mx/backend/public/api/user/" + item.id
        )
        .then((response) => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.registers_loading = false));
    },
  },
};
</script>
